import { Controller } from 'stimulus'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      slidesPerView: 2,
    })
  }
}
