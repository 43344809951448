import { Controller } from 'stimulus'
import Swiper, { Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use( Autoplay )

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      slidesPerView: 2,
      spaceBetween: 30,
      loop:true,
      autoplay: {
        delay: 3000
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
          loop: true,
          autoplay: false
        },
      },
    })
  }
}
