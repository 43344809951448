import { Controller } from 'stimulus'
import Swiper, { Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Pagination)

export default class extends Controller {
  static targets = ['pagination']

  connect() {
    new Swiper(this.element, {
      slidesPerView: 1,
      pagination: {
        clickable: true,
        el: this.paginationTarget,
      },
    })
  }
}
